import { FunctionContext } from '@wix/yoshi-flow-editor/serverless';
import { Set } from '@wix/ambassador-members-v1-member/types';

import { MemberAbout } from './member';

export type AspectsStore = FunctionContext['aspects'];

export type APIGatewayClient = FunctionContext['apiGatewayClient'];

export interface InitialDataRequest {
  viewedMemberId: string;
  currentMemberId: string | null;
  postsPerPage: number;
  language: string;
  fetchMemberFieldSet: Set;
  isRTL: boolean;
  isMultilingualEnabled: boolean;
  showAboutSection?: boolean;
  showJoinDate?: boolean;
  showCommentsStats?: boolean;
  showLikesReceivedStats?: boolean;
  showTopCommentsStats?: boolean;
  showBadgesSection?: boolean;
  showOverviewSection?: boolean;
  showBlogPostsSection?: boolean;
}

export interface LoadMoreBlogPostsRequest {
  viewedMemberId: string;
  postsPerPage: number;
  pagingOffset: number;
  language: string;
  isMultilingualEnabled: boolean;
}

export interface SaveAboutRequest {
  about: MemberAbout;
}

export interface RefetchMemberBadgesRequest {
  assignedBadgeIds: string[];
}

export enum ReservedFieldKey {
  Addresses = 'customfields.system.address',
  Phones = 'customfields.system.phone',
  Emails = 'customfields.system.email',
  FirstName = 'customfields.system.firstname',
  LastName = 'customfields.system.lastname',
  Company = 'customfields.contact.company',
  Position = 'customfields.contact.position',
  BirthDate = 'customfields.contact.birthdate',
}

export enum ServerErrorCode {
  TooManyLinksInAbout = 'TOO_MANY_LINKS_IN_ABOUT',
  TextTooLongInAbout = 'TEXT_TOO_LONG_IN_ABOUT',
}
